import { uniqBy } from "lodash";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  PaymentForm,
  CreditCard,
  GooglePay,
  Ach,
} from "react-square-web-payments-sdk";
import { auth } from "./../firebase_config";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Divider,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  processSquarePayment,
  clearPaymentError,
  clearTxnId,
} from "./../slices/payments";
import { fetchUserCards } from "./../slices/user";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  marginBottom: theme.spacing(1),
  borderColor: theme.palette.primary.contrastText,
  borderWidth: "1px",
  borderStyle: "solid",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  width: "100%",
}));

const SquarePaymentForm = ({ pay, same, note }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const [idToken, setIdToken] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [txId, setTxId] = useState(null);
  const [selectedCard, setSelectedCard] = useState("");

  const { loading, error, lastPaymentId } = useSelector(
    (state) => state.payments,
  );
  const { savedCards } = useSelector((state) => state.user);
  const squareCustomerId = user?.squareCustomerId;

  const handlePaymentMethodSubmit = async (token) => {
    if ((token && token.status === "OK") || selectedCard) {
      dispatch(
        processSquarePayment({
          sourceId: selectedCard || token.token,
          amount: Math.round(pay), // Ensure amount is in cents and an integer
          currency: "USD",
          locationId: process.env.REACT_APP_SQUARE_LOCATION_ID,
          idToken: idToken,
          customerId: user?.squareCustomerId || "",
          note,
        }),
      );
    } else {
      console.error("Payment token generation failed or no ID token available");
    }
  };

  // Use lodash to filter unique cards by fingerprint
  const uniqueCards = uniqBy(savedCards, "fingerprint");

  const createPaymentRequest = () => ({
    countryCode: "US",
    currencyCode: "USD",
    total: {
      label: "Total",
      amount: (pay / 100).toFixed(2),
      pending: true,
      note,
    },
    requestShippingAddress: false,
  });

  useEffect(() => {
    setTxId(uuidv4());
  }, [pay]);

  useEffect(() => {
    return () => {
      dispatch(clearPaymentError());
    };
  }, [dispatch]);

  useEffect(() => {
    if (squareCustomerId) {
      dispatch(fetchUserCards(squareCustomerId));
    }
  }, [dispatch, squareCustomerId]);

  useEffect(() => {
    const getToken = async () => {
      const user = auth.currentUser;
      if (user) {
        const token = await user.getIdToken();
        setIdToken(token);
      }
    };
    getToken();
  }, [auth]);

  const formWidth = isMobile ? "100%" : "400px";

  return (
    <Box sx={{ width: formWidth, margin: "auto" }}>
      {lastPaymentId ? (
        <>
          <Alert severity="success">
            Payment processed successfully! Payment ID: {lastPaymentId}
          </Alert>
          <Button
            color="info"
            variant="contained"
            sx={{ mt: 1 }}
            onClick={() => dispatch(clearTxnId())}
          >
            Make Another Payment
          </Button>
        </>
      ) : (
        txId && (
          <>
            {uniqueCards && uniqueCards.length > 0 && (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="saved-card-select-label">
                  Select Saved Card
                </InputLabel>
                <Select
                  labelId="saved-card-select-label"
                  value={selectedCard}
                  label="Select Saved Card"
                  onChange={(e) => setSelectedCard(e.target.value)}
                >
                  <MenuItem value="">Use New Payment Method</MenuItem>
                  {uniqueCards.map((card) => (
                    <MenuItem key={card.id} value={card.id}>
                      {`${card.cardBrand} **** ${card.last4} (Exp: ${card.expMonth}/${card.expYear})`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {selectedCard ? (
              <LoadingButton
                fullWidth
                loading={loading}
                variant="contained"
                color="primary"
                onClick={handlePaymentMethodSubmit}
                sx={{
                  p: 1.5,
                  textTransform: "none",
                  fontSize: "1rem",
                }}
              >
                Pay Total
              </LoadingButton>
            ) : (
              <PaymentForm
                applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
                locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
                cardTokenizeResponseReceived={handlePaymentMethodSubmit}
                createPaymentRequest={createPaymentRequest}
                transactionId={txId}
              >
                <CreditCard
                  postalCode="70301"
                  buttonProps={{
                    css: {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      marginBottom: theme.spacing(1),
                      borderColor: theme.palette.primary.contrastText,
                      borderWidth: "1px",
                      borderStyle: "solid",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.dark,
                      },
                      width: "100%",
                    },
                  }}
                >
                  {loading ? (
                    <div loading variant="outlined">
                      Processing
                    </div>
                  ) : (
                    <Typography>
                      {same ? "Pay Total" : `Pay $${(pay / 100).toFixed(2)}`}
                    </Typography>
                  )}
                </CreditCard>
                <Divider sx={{ my: 2 }} />
                {/*
            <GooglePay />
            <Divider sx={{ my: 2 }} />
            */}
                <Ach
                  accountHolderName={`${user?.firstName} ${user?.lastName}`}
                  redirectURI="https://portal.shelby-estates.com"
                  transactionId={txId}
                  buttonProps={{
                    css: {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      marginBottom: theme.spacing(1),
                      borderColor: theme.palette.primary.contrastText,
                      borderWidth: "1px",
                      borderStyle: "solid",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.dark,
                      },
                      width: "100%",
                    },
                  }}
                />
              </PaymentForm>
            )}
          </>
        )
      )}
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default SquarePaymentForm;
