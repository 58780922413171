/*global fetch */
import React from "react";
import { PaymentForm, CreditCard, Ach } from "react-square-web-payments-sdk";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material";

import { auth } from "./../firebase_config";

const SubscriptionForm = () => {
  const { user } = useSelector((state) => state.auth); // Assuming you have a Firebase auth hook
  const currentUser = auth.currentUser;
  const theme = useTheme();

  const handleCardTokenize = async (token) => {
    if (token.status === "OK" && user) {
      // Pass the token to your backend to create a customer and store card details
      const response = await fetch(
        "https://us-central1-shelby-estates-hoa.cloudfunctions.net" +
          "/createCustomerAndSubscription",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await currentUser.getIdToken()}`, // Send Firebase token
          },
          body: JSON.stringify({
            sourceId: token.token, // Token from Square
            uid: currentUser.uid,
            locationId: process.env.REACT_APP_SQUARE_LOCATION_ID,
          }),
        },
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Subscription setup successful:", result);
      } else {
        const error = await response.json();
        console.error("Subscription setup failed", error);
      }
    } else {
      console.error("Tokenization failed or user not authenticated");
    }
  };

  currentUser.getIdToken().then((t) => console.log({ user, token: t }));
  return (
    <PaymentForm
      applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
      locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
      cardTokenizeResponseReceived={handleCardTokenize}
    >
      <CreditCard />
    </PaymentForm>
  );
};

export default SubscriptionForm;
