/*global fetch */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ref, push, serverTimestamp } from "firebase/database";
import { httpsCallable } from "firebase/functions";

import { db, functions } from "./../firebase_config";
import { enqueueSnackbar } from "./notifications";
import { fetchRecentTransactions } from "./user";

export const processSquarePayment = createAsyncThunk(
  "payment/processSquarePayment",
  async (
    { sourceId, amount, currency, locationId, idToken, note, customerId },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await fetch(
        "https://us-central1-shelby-estates-hoa.cloudfunctions.net/processSquarePayment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            sourceId,
            amount,
            currency,
            locationId,
            customerId,
            note,
          }),
        },
      );
      const data = await response.json();
      if (response.ok && data.success) {
        await push(ref(db, `transactions/${data.userId}`), {
          ...data,
        });
        dispatch(fetchRecentTransactions(data.userId));
        return data;
      } else {
        return rejectWithValue(data.error || "Payment processing failed");
      }
    } catch (error) {
      console.error("Error in processSquarePayment:", error);
      return rejectWithValue(
        "An unexpected error occurred while processing your payment",
      );
    }
  },
);

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    loading: false,
    error: null,
    lastPaymentId: null,
  },
  reducers: {
    clearPaymentError: (state) => {
      state.error = null;
    },
    clearTxnId: (state) => {
      state.lastPaymentId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(processSquarePayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(processSquarePayment.fulfilled, (state, action) => {
        state.loading = false;
        state.lastPaymentId = action.payload.paymentId;
      })
      .addCase(processSquarePayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearPaymentError, clearTxnId } = paymentSlice.actions;

export default paymentSlice.reducer;
